import { AdviceEditor } from 'modules/ui/SqlAutocomplete/types';

export const getIncisionAndIndicatorsCompletions = <T extends { name: string }>(incisionsAndIndicators: T[]) =>
  (incisionsAndIndicators || []).reduce<AdviceEditor[]>((acc, el: T) => {
    acc.push({ caption: el.name, value: el.name });
    return acc;
  }, []);

export const setLimitInSqlRequest = ({ sql, newLimit }: { sql: string; newLimit: number }) => {
  if (/LIMIT \d+/.test(sql)) {
    return sql.replace(/LIMIT \d+/, `LIMIT ${newLimit}`);
  }
  return `${sql} LIMIT ${newLimit}`;
};
