import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LIMIT_EXPORT_DATA } from 'constants/global';
import { serverErrorText } from 'constants/ServerCode';
import Snackbar from 'services/Snackbar';
import { TState } from 'store/index';
import { removeAstOfVisualisationActions } from 'store/reducers/ast/actions';
import { addToLayerAction, deleteFromLayerByIdAction, setActiveBoardElementAction } from 'store/reducers/board/actions';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { getActivePageId } from 'store/reducers/projectPages/getters';
import { SettingsSnapshotType } from 'store/reducers/projectSettings/settingsSnapshotService';
import {
  loadAllPreviewTemplateVisualisations,
  loadDataBySql,
  loadTemplateVisualisation,
  loadVisualisationsByPageId,
  VisualisationDataType,
} from 'store/reducers/visualisations/api';
import { exportByType, initialVisualisationStoreState, referenceVisualisations } from 'store/reducers/visualisations/constants';
import {
  getActiveVisualisationSettings,
  getVisualisationsAlreadyLoaded,
  getWidgetsByPageId,
} from 'store/reducers/visualisations/getters';
import {
  AddNewVisualisationByDataPayload,
  AllPreviewTemplateVisualisationsListInterface,
  BubbleDataSettings,
  BubbleViewSettings,
  DefaultBackgroundImagesSettingsInterface,
  DefaultDataSettingsInterface,
  DefaultDataSettingsWithActiveIncisionIdInterface,
  DefaultSqlDataInterface,
  DefaultViewSettingsInterface,
  DefaultVisualisationOptionsType,
  EventsSettingsInterface,
  ExportDataInterface,
  HeatmapDataSettings,
  HeatmapViewSettings,
  LineAndBarDataSettings,
  LineAndBarViewSettings,
  LoadVisualisationsOutput,
  LoadVisualisationValuesPayload,
  PieDataSettings,
  PieViewSettings,
  TableDataSettings,
  TableViewSettings,
  TemplateVisualisationActionPayload,
  TextBackgroundSettings,
  TextDataSettings,
  TextViewSettings,
  TreeDataSettings,
  TreeViewSettings,
  UpdateDataSettingsPayload,
  UpdatePositionConfigPayload,
  UpdateSqlDataPayload,
  UpdateVisibleSettingsPayload,
  VisualisationActionsTypes,
  VisualisationIdsByPageInterface,
  VisualisationTypeType,
  VisualisationValuesInterface,
  VisualisationValuesPayload,
  WaterfallDataSettings,
  WaterfallViewSettings,
} from 'store/reducers/visualisations/types';
import { PageIdInterface, ProjectIdWithType } from 'types/store';
import { setLimitInSqlRequest } from 'utils/sqlSettings';
import { generateWidgetName, mergeByReference } from 'utils/utils';
import { v4 } from 'uuid';
import {
  addNewTemplateVisualisation,
  addNewVisualisation,
  addNewVisualisationByData,
  addToVisualizationErrorList,
  removeFromVisualisationLoadingListById,
  removeFromVisualizationErrorList,
  removeVisualisationById,
  removeVisualisationValuesById,
  setSlice,
  updateBackgroundSettingsData,
  updateDataSettingsData,
  updateDataSettingsIndicatorStackSumData,
  updateEventsData,
  updateLoadingList,
  updatePositionConfig,
  updateSqlData,
  updateViewSettingsData,
  updateVisibleSettings,
  updateVisualisationValuesById,
} from './index';

/* TODO: Adding types and interface for Error Messages  */
const validateError = (err: AxiosError, rejectWithValue?: any) => {
  const error: AxiosError = err;
  if (!error.response) {
    throw err;
  }

  const errorCode = error.response.status;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const errorMessage: string = error?.response?.data?.message || serverErrorText[errorCode];
  Snackbar.show(errorMessage, 'error');
  return rejectWithValue?.(errorMessage);
};

export const loadVisualisationsAction = createAsyncThunk(
  VisualisationActionsTypes.LOAD_VISUALISATIONS,
  async (params: ProjectIdWithType<PageIdInterface>, { dispatch, getState, signal }) => {
    const visualisationsAlreadyLoaded = getVisualisationsAlreadyLoaded(params.pageId)(getState() as TState);

    if (!visualisationsAlreadyLoaded) {
      const request = dispatch(loadVisualisationsByPageIdAction(params));

      signal.addEventListener('abort', () => {
        request.abort();
      });
    }
  },
);

export const loadVisualisationsByPageIdAction = createAsyncThunk<LoadVisualisationsOutput, ProjectIdWithType<PageIdInterface>>(
  VisualisationActionsTypes.LOAD_VISUALISATIONS_BY_PAGE_ID,
  async (params, { rejectWithValue, signal }) => {
    try {
      const response = await loadVisualisationsByPageId(params, { signal });

      const originVisualisations = response.data.visualisations as DefaultVisualisationOptionsType[];

      /* Merging reference visualization data with visualization data stored on the BE. When new data (new features) is added to the visualization, this data will be synchronized with the BE data. */
      const visualisations = mergeByReference({
        originData: originVisualisations,
        getReferenceByType: (type) => referenceVisualisations[type as VisualisationTypeType],
        typeKey: 'visualisationType',
      });

      const visualisationsByPages = { [params.pageId]: new Set(originVisualisations.map(({ id }) => id)) };

      return { visualisationsByPages, visualisations };
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ visualisationsByPages: {}, visualisations: {} });
    }
  },
);

export const exportVisualisationByIdAction = createAsyncThunk(
  VisualisationActionsTypes.EXPORT_XLSX_BY_ID,
  async (params: ProjectIdWithType<ExportDataInterface>, { rejectWithValue }) => {
    try {
      const { data: dataSql, visualizationId, exportType } = params;

      const newSqlRequestWithLimit = setLimitInSqlRequest({ sql: dataSql.queries.query, newLimit: LIMIT_EXPORT_DATA });

      const { data } = await exportByType[exportType]({
        ...params,
        data: {
          ...dataSql,
          queries: { ...dataSql.queries, query: newSqlRequestWithLimit },
        },
      });

      if (data) {
        const { nameFile } = dataSql;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data], { type: 'application/octet-stream' }));
        link.download = `${nameFile || visualizationId}.${exportType}`;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return rejectWithValue({ visualisationsByPages: {}, visualisations: {} });
    }
  },
);

export const loadAllPreviewTemplateVisualisationsAction = createAsyncThunk<AllPreviewTemplateVisualisationsListInterface[], void>(
  VisualisationActionsTypes.LOAD_ALL_PREVIEW_TEMPLATE_VISUALISATIONS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await loadAllPreviewTemplateVisualisations();
      return response.data.templateVisualisationList;
    } catch (err: any) {
      validateError(err, rejectWithValue);
      return [] as AllPreviewTemplateVisualisationsListInterface[];
    }
  },
);

export const loadTemplateVisualisationAction = createAsyncThunk<
  DefaultVisualisationOptionsType,
  TemplateVisualisationActionPayload
>(VisualisationActionsTypes.LOAD_TEMPLATE_VISUALISATION, async ({ templateVisualisationId, pageId }, { rejectWithValue }) => {
  try {
    const response = await loadTemplateVisualisation({ templateVisualisationId, pageId });
    return response.data.templateVisualisation.payload as DefaultVisualisationOptionsType;
  } catch (err: any) {
    validateError(err, rejectWithValue);
    return {} as DefaultVisualisationOptionsType;
  }
});

export const loadVisualisationsFromSnapshotAction = createAsyncThunk<
  LoadVisualisationsOutput,
  SettingsSnapshotType['visualisations']
>(VisualisationActionsTypes.LOAD_VISUALISATIONS_FROM_SNAPSHOT, (visualisations) => {
  const originVisualisations = visualisations.reduce<DefaultVisualisationOptionsType[]>(
    (result, data) => [...result, ...data.visualisations],
    [] as DefaultVisualisationOptionsType[],
  );

  /* Merging reference visualization data with visualization data stored on the BE. When new data (new features) is added to the visualization, this data will be synchronized with the BE data. */
  const mergedVisualisations = mergeByReference({
    originData: originVisualisations,
    getReferenceByType: (type) => referenceVisualisations[type as VisualisationTypeType],
    typeKey: 'visualisationType',
  });

  const visualisationsByPages = visualisations.reduce<VisualisationIdsByPageInterface>(
    (result, { pageId, visualisations }) => ({ ...result, [pageId]: new Set<string>(visualisations.map(({ id }) => id)) }),
    {},
  );

  return { visualisationsByPages, visualisations: mergedVisualisations };
});

export const clearVisualisationsStore = createAsyncThunk(
  VisualisationActionsTypes.CLEAR_VISUALISATIONS_STORE,
  (_, { dispatch }) => {
    dispatch(setSlice(initialVisualisationStoreState));
  },
);

export const addVisualisationByData = createAsyncThunk(
  VisualisationActionsTypes.ADD_VISUALISATION_BY_DATA,
  (data: AddNewVisualisationByDataPayload, { dispatch }) => {
    dispatch(addNewVisualisationByData(data));
  },
);

export const addVisualisationAction = createAsyncThunk(
  VisualisationActionsTypes.ADD_VISUALISATION,
  (visualisationType: VisualisationTypeType, { getState, dispatch }) => {
    const pageId = getActivePageId(getState() as TState) || '';
    const visualisations = getWidgetsByPageId(pageId)(getState() as TState);
    const name = generateWidgetName(visualisations, visualisationType);
    const id = v4();

    dispatch(addNewVisualisation({ visualisationType, data: { pageId, id, name } }));
    dispatch(addToLayerAction(id));
    dispatch(setActiveBoardElementAction(id));
  },
);

export const addNewTemplateVisualisationAction = createAsyncThunk(
  VisualisationActionsTypes.ADD_TEMPLATE_VISUALISATION,
  (data: DefaultVisualisationOptionsType, { dispatch }) => {
    const id = data.id;

    dispatch(addNewTemplateVisualisation(data));
    dispatch(addToLayerAction(id));
    dispatch(setActiveBoardElementAction(id));
  },
);

export const removeVisualisationByIdAction = createAsyncThunk(
  VisualisationActionsTypes.REMOVE_VISUALISATION_BY_ID,
  (id: string, { dispatch }) => {
    dispatch(removeVisualisationById(id));
    dispatch(deleteFromLayerByIdAction(id));
    dispatch(removeAstOfVisualisationActions(id));
    dispatch(removeVisualisationValuesAction(id));
    dispatch(removeFromVisualisationLoadingListAction(id));
  },
);

export const updateSqlSettingsActionById = createAsyncThunk<void, UpdateSqlDataPayload>(
  VisualisationActionsTypes.UPDATE_SQL_SETTINGS_BY_ID,
  ({ id, data }, { dispatch }) => {
    dispatch(updateSqlData({ id, data }));
  },
);

export const updatePositionConfigByIdAction = createAsyncThunk<void, UpdatePositionConfigPayload>(
  VisualisationActionsTypes.UPDATE_POSITION_CONFIG_BY_ID,
  ({ id, positionConfig }, { dispatch }) => {
    dispatch(updatePositionConfig({ id, positionConfig }));
  },
);

export const updatePositionConfigAction = createAsyncThunk<void, UpdatePositionConfigPayload['positionConfig']>(
  VisualisationActionsTypes.UPDATE_POSITION_CONFIG,
  (positionConfig, { dispatch, getState }) => {
    const id = getActiveBoardElement(getState() as TState);

    id && dispatch(updatePositionConfigByIdAction({ id, positionConfig }));
  },
);

export const updateVisibleConfigAction = createAsyncThunk<void, UpdateVisibleSettingsPayload['isVisible']>(
  VisualisationActionsTypes.UPDATE_POSITION_CONFIG,
  (isVisible, { dispatch, getState }) => {
    const id = getActiveBoardElement(getState() as TState);

    id && dispatch(updateVisibleSettings({ id, isVisible }));
  },
);

export const updateSqlSettingsAction = createAsyncThunk<void, Partial<DefaultSqlDataInterface>>(
  VisualisationActionsTypes.UPDATE_SQL_SETTINGS,
  (data, { dispatch, getState }) => {
    const id = getActiveBoardElement(getState() as TState);

    id && dispatch(updateSqlSettingsActionById({ id, data }));
  },
);

export const updateEventSettingsAction = createAsyncThunk<void, Partial<EventsSettingsInterface>>(
  VisualisationActionsTypes.UPDATE_EVENT_SETTINGS,
  (events, { dispatch, getState }) => {
    const id = getActiveBoardElement(getState() as TState);

    id && dispatch(updateEventsData({ id, events }));
  },
);

export const updateViewSettingsTypedAction = <VisualisationViewSettings extends DefaultViewSettingsInterface>() =>
  createAsyncThunk<void, Partial<VisualisationViewSettings>>(
    VisualisationActionsTypes.UPDATE_VIEW_SETTINGS,
    (viewSettings, { dispatch, getState }) => {
      const id = getActiveBoardElement(getState() as TState);
      id && dispatch(updateViewSettingsData({ id, viewSettings }));
    },
  );

export const updateBackgroundSettingsTypedAction = <
  VisualisationBackgroundSettings extends DefaultBackgroundImagesSettingsInterface,
>() =>
  createAsyncThunk<void, Partial<VisualisationBackgroundSettings>>(
    VisualisationActionsTypes.UPDATE_BACKGROUND_DATA_SETTINGS,
    (backgroundSettings, { dispatch, getState }) => {
      const id = getActiveBoardElement(getState() as TState);
      id && dispatch(updateBackgroundSettingsData({ id, backgroundSettings }));
    },
  );

export const updateDataSettingsByIdTypedAction = <
  VisualisationDataSettings extends DefaultDataSettingsInterface = DefaultDataSettingsInterface,
>() =>
  createAsyncThunk<void, UpdateDataSettingsPayload<VisualisationDataSettings>>(
    VisualisationActionsTypes.UPDATE_DATA_SETTINGS,
    ({ dataSettings, id }, { dispatch }) => {
      dispatch(updateDataSettingsData({ id, dataSettings }));
    },
  );

export const updateLineAndBarDataSettingsIndicatorStackSumByIdAction = <
  VisualisationDataSettings extends DefaultDataSettingsInterface = DefaultDataSettingsInterface,
>() =>
  createAsyncThunk<void, UpdateDataSettingsPayload<VisualisationDataSettings>>(
    VisualisationActionsTypes.UPDATE_DATA_SETTINGS,
    ({ dataSettings, id }, { dispatch }) => {
      dispatch(updateDataSettingsIndicatorStackSumData({ id, dataSettings }));
    },
  );

/* TODO Use updateDataSettingsByIdTypedAction instead of updateDataSettingsTypedAction   */
export const updateDataSettingsById = updateDataSettingsByIdTypedAction();
export const updateLineAndBarDataSettingsIndicatorStackSumById = updateLineAndBarDataSettingsIndicatorStackSumByIdAction();

export const updatePieDataSettingsById = updateDataSettingsByIdTypedAction<PieDataSettings>();
export const updateHeatmapDataSettingsById = updateDataSettingsByIdTypedAction<HeatmapDataSettings>();
export const updateBubbleDataSettingsById = updateDataSettingsByIdTypedAction<BubbleDataSettings>();
export const updateTableDataSettingsById = updateDataSettingsByIdTypedAction<TableDataSettings>();
export const updateLineAndBarDataSettingsById = updateDataSettingsByIdTypedAction<LineAndBarDataSettings>();

export const updateDataSettingsTypedAction = <VisualisationDataSettings extends DefaultDataSettingsInterface>() =>
  createAsyncThunk<void, Partial<VisualisationDataSettings>>(
    VisualisationActionsTypes.UPDATE_DATA_SETTINGS,
    (dataSettings, { dispatch, getState }) => {
      const id = getActiveBoardElement(getState() as TState);

      id && dispatch(updateDataSettingsById({ id, dataSettings }));
    },
  );

export const updateDataSettingsIndicatorStackSumDataAction = <VisualisationDataSettings extends DefaultDataSettingsInterface>() =>
  createAsyncThunk<void, Partial<VisualisationDataSettings>>(
    VisualisationActionsTypes.UPDATE_INDICATOR_STACK_SUM,
    (dataSettings, { dispatch, getState }) => {
      const id = getActiveBoardElement(getState() as TState);

      id && dispatch(updateLineAndBarDataSettingsIndicatorStackSumById({ id, dataSettings }));
    },
  );

export const updateLineAndBarIndicatorStackSumAction = updateDataSettingsIndicatorStackSumDataAction<LineAndBarDataSettings>();

export const updateVisualisationValuesAction = createAsyncThunk<void, VisualisationValuesPayload>(
  VisualisationActionsTypes.UPDATE_VISUALISATION_VALUES,
  (params, { dispatch }) => {
    dispatch(updateVisualisationValuesById(params));
  },
);

export const removeVisualisationValuesAction = createAsyncThunk<void, string>(
  VisualisationActionsTypes.REMOVE_VISUALISATION_VALUES,
  (id, { dispatch }) => {
    dispatch(removeVisualisationValuesById(id));
  },
);

export const removeFromVisualisationLoadingListAction = createAsyncThunk<void, string>(
  VisualisationActionsTypes.REMOVE_FROM_VISUALISATION_LOADING_LIST,
  (id, { dispatch }) => {
    dispatch(removeFromVisualisationLoadingListById(id));
  },
);

export const loadVisualisationValuesAction = createAsyncThunk<
  VisualisationDataType,
  Omit<LoadVisualisationValuesPayload, 'visualisationId'>
>(
  VisualisationActionsTypes.LOAD_VISUALISATION_VALUES,
  async ({ projectId, sqlRequest, modelId }, { rejectWithValue, signal }) => {
    try {
      const response = await loadDataBySql(
        {
          project: projectId,
          sql: sqlRequest,
          modelId,
          /*TODO: Comment on RLS and wait for the server */
          // rls
        },
        { signal },
      );
      return response.data;
    } catch (err: any) {
      /* TODO: Make normal validating error and return correct value instead of error text like now */
      return rejectWithValue(err.response.data);
    }
  },
);

export const updateDataSettingsWithSqlDataAction = <VisualisationDataSettings extends DefaultDataSettingsInterface>() =>
  createAsyncThunk<void, Partial<VisualisationDataSettings>>(
    VisualisationActionsTypes.UPDATE_DATA_SETTINGS_SQL_DATA,
    (dataSettings, { dispatch, getState }) => {
      const id = getActiveBoardElement(getState() as TState);
      const activeVisualization = getActiveVisualisationSettings(getState() as TState);

      if (id && activeVisualization) {
        const oldDataSettings = activeVisualization.dataSettings;

        const incisions = dataSettings.incisions?.map((incision) => {
          let colors;
          const oldObj = oldDataSettings.incisions.find((obj) => obj.name === incision.name);

          if (oldObj) {
            colors = incision.colors !== undefined ? oldObj.colors || incision.colors : undefined;

            return {
              ...incision,
              colors,
              fictionalData: oldObj.fictionalData,
              settings: { ...oldObj.settings, customRequest: incision.settings.customRequest },
            };
          }

          return incision;
        });

        const indicators = dataSettings.indicators?.map((indicator) => {
          let colors;
          const oldObj = oldDataSettings.indicators.find((obj) => obj.name === indicator.name);

          if (oldObj) {
            colors = indicator.color !== undefined ? oldObj.color || indicator.color : undefined;

            return {
              ...indicator,
              colors,
              settings: oldObj.settings || indicator.settings,
            };
          }
          return indicator;
        });

        dispatch(updateDataSettingsById({ id, dataSettings: { ...dataSettings, incisions, indicators } }));
      }
    },
  );

export const getVisualisationValuesAction = createAsyncThunk<void, LoadVisualisationValuesPayload>(
  VisualisationActionsTypes.GET_VISUALISATION_VALUES,
  async ({ visualisationId, projectId, sqlRequest, modelId, rls }, { dispatch, signal }) => {
    const request = dispatch(loadVisualisationValuesAction({ projectId, sqlRequest, modelId, rls }));

    signal.addEventListener('abort', () => {
      request.abort();
    });

    request
      .then(({ payload: visualisationValues }: any) => {
        if (visualisationValues) {
          dispatch(
            updateVisualisationValuesAction({ data: visualisationValues as VisualisationValuesInterface, id: visualisationId }),
          );
        }

        if (visualisationValues?.message) {
          const { message } = visualisationValues;

          throw new Error(message);
        }

        dispatch(removeFromVisualizationErrorList({ id: visualisationId }));
      })
      .catch((err: string) => {
        dispatch(addToVisualizationErrorList({ id: visualisationId, text: err }));
      })
      .finally(() => {
        dispatch(updateLoadingList({ id: visualisationId }));
      });
  },
);

export const updateViewSettingsAction = updateViewSettingsTypedAction<DefaultViewSettingsInterface>();
export const updateTreeViewSettingsAction = updateViewSettingsTypedAction<TreeViewSettings>();
export const updatePieViewSettingsAction = updateViewSettingsTypedAction<PieViewSettings>();
export const updateTextViewSettingsAction = updateViewSettingsTypedAction<TextViewSettings>();
export const updateWaterfallViewSettingsAction = updateViewSettingsTypedAction<WaterfallViewSettings>();
export const updateBubbleViewSettingsAction = updateViewSettingsTypedAction<BubbleViewSettings>();
export const updateHeatmapViewSettingsAction = updateViewSettingsTypedAction<HeatmapViewSettings>();
export const updateLineAndBarViewSettingsAction = updateViewSettingsTypedAction<LineAndBarViewSettings>();
export const updateTableViewSettingsAction = updateViewSettingsTypedAction<TableViewSettings>();

export const updateDataSettingsWithSqlAction =
  updateDataSettingsWithSqlDataAction<DefaultDataSettingsWithActiveIncisionIdInterface>();
export const updateDataSettingsAction = updateDataSettingsTypedAction<DefaultDataSettingsWithActiveIncisionIdInterface>();
export const updateTreeDataSettingsAction = updateDataSettingsTypedAction<TreeDataSettings>();
export const updateLineAndBarDataSettingsAction = updateDataSettingsTypedAction<LineAndBarDataSettings>();
export const updateWaterfallDataSettingsAction = updateDataSettingsTypedAction<WaterfallDataSettings>();
export const updateHeatmapDataSettingsAction = updateDataSettingsTypedAction<HeatmapDataSettings>();
export const updateBubbleDataSettingsAction = updateDataSettingsTypedAction<BubbleDataSettings>();
export const updatePieDataSettingsAction = updateDataSettingsTypedAction<PieDataSettings>();
export const updateTextDataSettingsAction = updateDataSettingsTypedAction<TextDataSettings>();
export const updateTableDataSettingsAction = updateDataSettingsTypedAction<TableDataSettings>();

export const updateTextBackgroundSettingsAction = updateBackgroundSettingsTypedAction<TextBackgroundSettings>();
